/*
 * Shopping cart
 */

/*
 * Functionality for manipulation of cart items
 */
document.addEventListener('alpine:init', function () {
  function setCartOperation(el, operation) {
    // Adds a `data-cart-operation` attribute with value `operation` to `el`
    if (el && !el.dataset.cartOperation) {
      el.dataset.cartOperation = operation;
    }
    const form = el.closest('form') || el;
    form.addEventListener(
      'htmx:afterRequest',
      () => {
        if (el.dataset.cartOperation) {
          delete el.dataset.cartOperation;
        }
      },
      {once: true},
    );
  }

  window.Alpine.data('cartQuantity', function () {
    return {
      input: null,
      requestInFlight: false,

      doAdjustItemQuantityField(clickedButton, amountToAdjust) {
        const currentVal = parseInt(this.input.value);
        const maximum = parseInt(this.input.max);
        if (currentVal <= 0) {
          return;
        }
        if (Number.isFinite(maximum) && currentVal + amountToAdjust > maximum) {
          return;
        }
        this.input.value = currentVal + amountToAdjust;
        this.input.dispatchEvent(new Event('change'));
      },

      /*
       * Binds
       */
      quantityField: {
        ['x-init']() {
          this.input = this.$el;
          if (this.input && this.input.form) {
            this.input.form.addEventListener(
              'htmx:beforeRequest',
              () => (this.requestInFlight = true),
            );
            this.input.form.addEventListener(
              'htmx:afterRequest',
              () => (this.requestInFlight = false),
            );
          }
        },

        ['@change.debounce']() {
          const container = this.$el.closest(
            '.cart-list__item,section[id|=product],form#add_to_cart_form',
          );
          if (container) {
            setCartOperation(container, 'adjust');
          }
          window.htmx.trigger(this.$el.form, 'submit', {});
        },

        [':readonly']() {
          return this.requestInFlight;
        },
      },

      /* Decrease quantity of cart item via minus button */
      quantityDecrease: {
        ['@click.prevent']() {
          const container = this.$el.closest(
            '.cart-list__item,section[id|=product],form#add_to_cart_form',
          );
          if (container) {
            setCartOperation(container, 'decrement');
          }
          this.doAdjustItemQuantityField(this.$el, -1);
        },

        [':disabled']() {
          return this.requestInFlight;
        },

        [':aria-controls']() {
          if (this.input && this.input.id) {
            return this.input.id;
          }
        },
      },

      /* Increase quantity of cart item via plus button */
      quantityIncrease: {
        ['@click.prevent']() {
          const container = this.$el.closest(
            '.cart-list__item,section[id|=product],form#add_to_cart_form',
          );
          if (container) {
            setCartOperation(container, 'increment');
          }
          this.doAdjustItemQuantityField(this.$el, 1);
        },

        [':disabled']() {
          const currentVal = parseInt(this.input.value);
          const maximum = parseInt(this.input.max);
          if (Number.isFinite(maximum) && currentVal === maximum) {
            return true;
          }
          return this.requestInFlight;
        },

        [':aria-controls']() {
          if (this.input && this.input.id) {
            return this.input.id;
          }
        },
      },
    };
  });

  window.Alpine.data('cartDelete', function () {
    return {
      requestInFlight: false,

      /*
       * Binds
       */
      deleteField: {
        ['x-init']() {
          this.input = this.$el;
          if (this.input && this.input.form) {
            this.input.form.addEventListener(
              'htmx:beforeRequest',
              () => (this.requestInFlight = true),
            );
            this.input.form.addEventListener(
              'htmx:afterRequest',
              () => (this.requestInFlight = false),
            );
          }
        },

        ['@change'](ev) {
          const container = ev.target.closest('.cart-list__item');
          setCartOperation(container, 'delete');
        },

        [':disabled']() {
          return this.requestInFlight;
        },
      },
    };
  });

  window.Alpine.data('moveToCart', function () {
    return {
      /*
       * Binds
       */
      moveToCartField: {
        ['@change'](ev) {
          const container = ev.target.closest('.cart-list__item');
          setCartOperation(container, 'move-to-cart');
        },
      },
    };
  });

  window.Alpine.data('saveForLater', function () {
    return {
      /*
       * Binds
       */
      saveForLaterField: {
        ['@change'](ev) {
          const container = ev.target.closest('.cart-list__item');
          setCartOperation(container, 'save-for-later');
        },
      },
    };
  });
});

/*
 * "Just added to cart" flyout
 */
document.addEventListener('alpine:init', function () {
  window.Alpine.data('cartJustAdded', function () {
    return {
      item: null,

      closeTimeout: undefined,

      init() {
        document.addEventListener(
          'cart_item_created',
          this.handleItemCreated.bind(this),
        );
      },

      handleItemCreated(ev) {
        if (this.closeTimeout !== undefined) {
          clearTimeout(this.closeTimeout);
        }
        this.item = ev.detail.item;
        this.closeTimeout = setTimeout(this.handleClose.bind(this), 10 * 1000);
      },

      handleClose() {
        if (this.closeTimeout !== undefined) {
          clearTimeout(this.closeTimeout);
        }
        this.item = null;
        this.$nextTick(() => this.$root.firstChild.remove());
      },

      isOpen() {
        return this.item !== null;
      },

      /**
       * Binds
       */

      closeButton: {
        ['@click']() {
          this.handleClose();
        },
      },
    };
  });
});

/*
 * Display toasts on actions
 */
document.addEventListener('alpine:init', function () {
  document.addEventListener('cart_item_deleted', (evt) => {
    window.Hooks.showToast({
      msg: `“${evt.detail.product_detail.title}” removed from your basket`,
      tag: 'cart_item_deleted',
    });
  });
  document.addEventListener('cart_item_saved_for_later', (evt) => {
    window.Hooks.showToast({
      msg: `“${evt.detail.product_detail.title}” saved for later`,
      tag: 'cart_item_saved_for_later',
    });
  });
  document.addEventListener('save_for_later_item_moved_to_cart', (evt) => {
    window.Hooks.showToast({
      msg: `“${evt.detail.product_detail.title}” added to your basket`,
      tag: 'save_for_later_item_moved_to_cart',
    });
  });
  document.addEventListener('save_for_later_item_deleted', (evt) => {
    window.Hooks.showToast({
      msg: `“${evt.detail.product_detail.title}” removed from your saved items`,
      tag: 'save_for_later_item_deleted',
    });
  });
});
